import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import type {OpenApplication} from '../../types/paragraphs'
import {AdditionalContent} from '../../types/api-types'
import {useState} from 'react'
import ApplicationForm from '../shared/ApplicationForm'

export type OpenApplicationProps = {
  paragraph: OpenApplication
  additionalContent: AdditionalContent
}

type FormStatus = {
  success: boolean
  message?: string
}

const OpenApplication = ({
  paragraph,
  additionalContent,
}: OpenApplicationProps) => {
  const [formStatus, setFormStatus] = useState<FormStatus | null>(null)
  const countries =
    additionalContent &&
    additionalContent['taxonomies'] &&
    additionalContent['taxonomies']['countries']
      ? additionalContent['taxonomies']['countries']
      : []

  return (
    <section className="bg-slate-gray-10 position-relative c-open-application">
      <Container className="section-box" fluid={true}>
        {!formStatus ? (
          <>
            <Row>
              <Col lg={{span: 4}}>
                {paragraph.field_title ? (
                  <h2>{paragraph.field_title}</h2>
                ) : null}
                {paragraph.field_description ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: paragraph.field_description.processed,
                    }}
                  />
                ) : null}
              </Col>

              <Col lg={{span: 8}}>
                <ApplicationForm
                  errorRequired={paragraph.field_error_required}
                  errorPhoneFormat={paragraph.field_phone_format_error}
                  errorEmailFormat={paragraph.field_email_format_message}
                  errorFileSize={paragraph.field_size_message}
                  errorFileExtension={paragraph.field_extension_message}
                  errorPrivacyCheckboxRequired={
                    paragraph.field_privacy_checkbox_required
                  }
                  labelFirstName={paragraph.field_first_name_label}
                  placeholderFirstName={paragraph.field_first_name_placeholder}
                  labelLastName={paragraph.field_last_name_label}
                  placeholderLastName={paragraph.field_last_name_placeholder}
                  labelEmail={paragraph.field_email_label}
                  placeholderEmail={paragraph.field_email_placeholder}
                  labelPhone={paragraph.field_phone_label}
                  placeholderPhone={paragraph.field_phone_placeholder}
                  labelCountry={paragraph.field_country_label}
                  labelZipCode={paragraph.field_zip_code_label}
                  placeholderZipCode={paragraph.field_zip_code_placeholder}
                  labelSubmitButton={paragraph.field_button_label}
                  disclaimerUpload={paragraph.field_upload_disclaimer}
                  labelPrivacyCheckbox={
                    paragraph.field_privacy_checkbox?.processed
                  }
                  labelCvUpload={paragraph.field_cv_upload}
                  setFormStatus={setFormStatus}
                  isOpenApplication
                  countries={countries}
                />
              </Col>
            </Row>
          </>
        ) : null}
        {formStatus?.success === true ? (
          <Col
            lg={{span: 6, offset: 3}}
            className="c-open-application__success"
          >
            <div
              className=" d-flex h-100 align-items-center"
              dangerouslySetInnerHTML={{
                __html: paragraph.field_success_message.value,
              }}
            ></div>
          </Col>
        ) : null}
        {formStatus?.success === false ? (
          <Col lg={{span: 6, offset: 3}} className="c-open-application__error">
            <div
              className=" d-flex h-100 align-items-center"
              dangerouslySetInnerHTML={{
                __html: paragraph.field_error_message.value,
              }}
            ></div>
          </Col>
        ) : null}
      </Container>
      <svg
        width="384"
        height="355"
        viewBox="0 0 384 355"
        fill="none"
        className="position-absolute bottom-0 d-none d-lg-block"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_15168_21229)">
          <path
            d="M237.882 432.789L82.8408 59.2681L53.9277 72.9771L207.888 447.196L237.882 432.789Z"
            fill="white"
          />
          <path
            d="M162.316 142.763L173.723 171.134L268.786 119.371L256.987 90.5804L162.316 142.763Z"
            fill="white"
          />
          <path
            d="M334.874 253.057L-39.0994 139.213L-45.2128 171.246L328.286 287.109L334.874 253.057Z"
            fill="white"
          />
          <g>
            <path
              d="M237.882 432.789L82.8408 59.2681L53.9277 72.9771L207.888 447.196L237.882 432.789Z"
              fill="#E4003A"
            />
          </g>
          <g>
            <path
              d="M162.316 142.763L173.723 171.134L268.786 119.371L256.987 90.5804L162.316 142.763Z"
              fill="#870B58"
            />
          </g>
          <g>
            <path
              d="M334.874 253.057L-39.0994 139.213L-45.2128 171.246L328.286 287.109L334.874 253.057Z"
              fill="#F8AD07"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_15168_21229">
            <rect
              width="351.463"
              height="395.883"
              fill="white"
              transform="translate(383.701 137.522) rotate(110.327)"
            />
          </clipPath>
        </defs>
      </svg>
    </section>
  )
}

export default OpenApplication
